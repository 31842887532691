<template>
	<div class="box_main">
		<div class="innderbox">
			<div class="headerbox">
				<img src="../../../../assets/laba.png" alt="" style="width: 0.48rem;height: auto;"/>
				<div style="font-size: 0.2rem;margin-left: 0.12rem;cursor: pointer;" @click="go('/system/get_file/daiban')">
					您有<span style="font-weight: bold;margin: 0 0.04rem;">{{headerNumber.dai}}</span>件待办收文需要处理，其中有<span style="color: #d00;font-weight: bold;margin: 0 0.04rem;">{{headerNumber.jjdai}}</span>件紧急；
				</div>
			</div>
			<div style="display: flex;justify-content: space-between;margin-top: 0.18rem;">
				<div class="searbox" style="width: 67.5%;">
					<div style="width: 100%;background-color: #fff;">
						<div style="padding: 0.17rem 0.2rem;border-bottom: 1px solid #eee;color: #1552B4;font-size: 0.24rem;">
							待办收文
						</div>
						<div style="width: 100%;padding: 0.2rem 0.25rem;">
							<div style="font-size: 0.2rem;display: flex;align-items: center;" class="lineitem" @click="showdetail(item)" v-for="(item,index) in dailist">
								<span>【{{item.jjcd}}】</span>
								<span style="width: 50%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
									{{item.name}}
								</span>
								<span style="margin-left: 3%;">{{item.swzh}}号</span>
								<span style="margin-left: 3%;color: #727272;">{{item.addtime}}</span>
							</div>
							<div v-if="dailist.length<=0" style="font-size: 0.2rem;color: #ccc;">
								暂无
							</div>
							<div @click="go('/system/get_file/daiban')" style="color: #8D8D8D;font-size: 0.18rem;display: flex;align-items: flex-end;justify-content: flex-end;margin-top: 0.3rem;cursor: pointer;">
								更多
							</div>
						</div>
					</div>
					
					<div style="width: 100%;margin-top: 0.18rem;background-color: #fff;">
						<div style="padding: 0.17rem 0.2rem;border-bottom: 1px solid #eee;color: #222222;font-size: 0.24rem;display: flex;align-items: center;justify-content: space-between;">
							<div style="color: #222222;font-size: 0.24rem;display: flex;align-items: center;">
								<img src="../../../../assets/titleimg.png" alt="" style="width: 0.12rem;height: auto;margin-right: 0.08rem;"/>
								收文数据
							</div>
							<div>
								<el-date-picker
								    v-model="searchmoth"
								    type="month"
								    placeholder="选择月"
									value-format="yyyy-MM"
									@change="getmidnumber"
									 style="width: 120px;">
								</el-date-picker>
							</div>
						</div>
						<div style="width: 100%;padding: 0.2rem 0.25rem;display: flex;align-items: center;justify-content: space-between;">
							<div style="display: flex;align-items: center;cursor: pointer;" @click="go('/system/get_file/aaa_new_my')">
								<img src="../../../../assets/file1.png" alt="" style="width: 0.47rem;"/>
								<div style="margin-left: 0.08rem;">
									<div style="font-size: 0.3rem;color: #333;">{{midNumber.fq}}</div>
									<div style="color: #727272;font-size: 0.16rem;">发起的收文</div>
								</div>
							</div>
							<div style="display: flex;align-items: center;cursor: pointer;" @click="go('/system/get_file/daiban')">
								<img src="../../../../assets/file2.png" alt="" style="width: 0.47rem;"/>
								<div style="margin-left: 0.08rem;">
									<div style="font-size: 0.3rem;color: #333;">{{midNumber.dcl}}</div>
									<div style="color: #727272;font-size: 0.16rem;">待处理的收文</div>
								</div>
							</div>
							<div style="display: flex;align-items: center;cursor: pointer;" @click="go('/system/get_file/caogao')">
								<img src="../../../../assets/file3.png" alt="" style="width: 0.47rem;"/>
								<div style="margin-left: 0.08rem;">
									<div style="font-size: 0.3rem;color: #333;">{{midNumber.zc}}</div>
									<div style="color: #727272;font-size: 0.16rem;">暂存的收文</div>
								</div>
							</div>
							<div style="display: flex;align-items: center;cursor: pointer;" @click="go('/system/get_file/zaiban')">
								<img src="../../../../assets/file4.png" alt="" style="width: 0.47rem;"/>
								<div style="margin-left: 0.08rem;">
									<div style="font-size: 0.3rem;color: #333;">{{midNumber.zb}}</div>
									<div style="color: #727272;font-size: 0.16rem;">在办的收文</div>
								</div>
							</div>
							<div style="display: flex;align-items: center;cursor: pointer;" @click="go('/system/get_file/yiban')">
								<img src="../../../../assets/file5.png" alt="" style="width: 0.47rem;"/>
								<div style="margin-left: 0.08rem;">
									<div style="font-size: 0.3rem;color: #333;">{{midNumber.yb}}</div>
									<div style="color: #727272;font-size: 0.16rem;">已办的收文</div>
								</div>
							</div>
						</div>
					</div>
				
					<div style="width: 100%;background-color: #fff;margin-top: 0.18rem;">
						<div style="padding: 0.17rem 0.2rem;border-bottom: 1px solid #eee;color: #222222;font-size: 0.24rem;display: flex;align-items: center;justify-content: space-between;">
							<div style="color: #222222;font-size: 0.24rem;display: flex;align-items: center;">
								<img src="../../../../assets/titleimg.png" alt="" style="width: 0.12rem;height: auto;margin-right: 0.08rem;"/>
								已办的公文
							</div>
							<div style="display: flex;align-items: center;">								
								<!-- <div style="width: 80px;">
									<el-select v-model="value2" placeholder="请选择">
									    <el-option label="个人" value="1">
									    </el-option>
									  </el-select>
								</div>	 -->
								<div>
									<el-date-picker
									    v-model="searchmoth2"
									    type="month"
									    placeholder="选择月"
										value-format="yyyy-MM"
										@change="getyblist"
										 style="width: 120px;">
									</el-date-picker>
								</div>
							</div>
							
						</div>
						<div style="width: 100%;padding: 0.2rem 0.25rem;">
							<div class="lineitem" @click="showdetail(item)" v-for="(item,index) in yblist" :key="index" style="font-size: 0.2rem;display: flex;align-items: center;margin-bottom: 0.1rem;">
								<span>【{{item.jjcd}}】</span>
								<span style="width: 50%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
									{{item.name}}
								</span>
								<span style="margin-left: 3%;">{{item.swzh}}号</span>
								<span style="margin-left: 3%;color: #727272;">{{item.addtime}}</span>
							</div>
							<div v-if="yblist.length<=0" style="font-size: 0.2rem;color: #ccc;margin-top: 15px;">
								暂无
							</div>
							<div  @click="go('/system/get_file/yiban')" style="color: #8D8D8D;font-size: 0.18rem;display: flex;align-items: flex-end;justify-content: flex-end;margin-top: 0.3rem;cursor: pointer;">
								更多
							</div>
						</div>
					</div>
				</div>
				
				
				
				
				<div style="width: 31%;">
					<div style="display: flex;align-items: center;width: 100%;justify-content: space-between;background-color: #fff;padding: 0.28rem;justify-content: center;">
						<div style="display: flex;align-items: center;cursor: pointer;" @click="go('/system/get_file/add')">
							<img src="../../../../assets/file_r2.png" alt=""  style="width: 0.67rem;height: auto;"/>
							<span style="color: #222222;font-size: 0.3rem;margin-left: 0.14rem;">收文登记</span>
						</div>
					</div>
					
					
					<div style="width: 100%;background-color: #fff;margin-top: 0.18rem;">
						<div style="padding: 0.17rem 0.2rem;border-bottom: 1px solid #eee;color: #1552B4;font-size: 0.24rem;">
							最新收文
						</div>
						<div style="width: 100%;padding: 0.2rem 0.25rem;">
							<div style="width: 100%;display: flex;align-items: center;">
								<el-input
								    placeholder="搜索"
								    suffix-icon="el-icon-search"
								    v-model="input1"
									size="small"
									@change="getdailist2"
									style="width: 90%;">
								</el-input>
								<div @click="go('/system/get_file/daiban')" style="color: #8D8D8D;font-size: 0.14rem;width: 10%;text-align: right;cursor: pointer;">
									更多
								</div>
							</div>
							<div class="lineitem" @click="showdetail(item)" style="font-size: 0.14rem;display: flex;align-items: center;margin-top: 0.2rem;" v-for="item in newlist">
								<span>【{{item.jjcd}}】</span>
								<span style="width: 50%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
									{{item.name}}
								</span>
								<span style="margin-left: 3%;color: #727272;">{{item.addtime}}</span>
							</div>
							<div v-if="newlist.length<=0" style="font-size: 0.2rem;color: #ccc;margin-top: 15px;">
								暂无
							</div>
							
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<detail ref="mydetail"></detail>
	</div>
</template>

<script>
	import detail from './components/detail.vue'
	export default {
		components:{ detail },
		data() {
			return {
				value:'1',
				value2:'1',
				input1:'',
				searchmoth:'',
				searchmoth2:'',
				headerNumber:{
					dai:0,
					jjdai:0
				},
				dailist:[],
				midNumber:{
					fq:0,
					dcl:0,
					zc:0,
					zb:0,
					yb:0
				},
				yblist:[],
				newlist:[]
			}
		},
		created(){
			this.getheadernumber()
			this.getdailist()
			this.getmidnumber()
			this.getyblist()
			this.getdailist2()
		},
		mounted(){
			
		},
		methods:{
			showdetail(row){
				this.$post({
					url: '/api/matter/details',
					params: {
						id:row.id
					}
				}).then((res) => {
					this.$refs.mydetail.info = res
					this.$refs.mydetail.dialogVisible = true
				})
			},
			go(path){
				this.$router.push(path)
			},
			getheadernumber(){
				this.$post({
					url: '/api/Matter/gwdb',
					params: {}
				}).then((res) => {
					this.headerNumber = res
				})
			},
			getmidnumber(){
				this.$post({
					url: '/api/Matter/gwsj',
					params: {
						month:this.searchmoth
					}
				}).then((res) => {
					this.midNumber = res
				})
			},
			getdailist(){
				this.$post({
					url: '/api/matter/dbl',
					params: {
						name: '',
						p: 1,
						size: 1,
					}
				}).then((res) => {
					this.dailist = res.list
				})
			},
			getdailist2(){
				this.$post({
					url: '/api/matter/zbl',
					params: {
						name: this.input1,
						p: 1,
						size: 10,
					}
				}).then((res) => {
					this.newlist = res.list
				})
			},
			getyblist(){
				this.$post({
					url: '/api/matter/ybl',
					params: {
						month:this.searchmoth2,
						name: '',
						p: 1,
						size: 3,
					}
				}).then((res) => {
					this.yblist = res.list
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.innderbox{
		width: 100%;
		height: 100%;
		.headerbox{
			width: 100%;
			padding: 0.2rem 0.26rem;
			background-color: #fff;
			display: flex;
			align-items: center;
		}
	}
	.box_main {
		background-color: #eee;
		padding: 0.15rem;
		width: 100%;
		box-sizing: border-box;
		min-height: 96%;
	}
	::v-deep .searbox .el-input--suffix .el-input__inner{
		border: none !important;
	}
	.lineitem{
		cursor: pointer;
	}
	.lineitem:hover{
		color: #409EFF !important;
	}
	::v-deep .el-dialog__header {
		display: flex !important;
	}
	
	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>